import { cardInfo, getRealNameStatus, myBalance, appContext } from "@/api/telecom";

const state = {
  appid: null,
  payType: null, // 付款形式
  rechargeValues: [], // 余额支付时可选择的充值数额
  needBindPhone: true, // 是否需要绑定手机
  balance: 0, // 余额
  setPassword: false, // 是否已经设置密码
  cardInfo: {},
  realname: false,
  validateUrl: "",
  accessNumber: "", //accessNumber 接入号
  carrierCompany: "" // 1是移动 6是联通 3、5是电信 carrierCompany为，3、5 时候，点实名直接复制iccid跳转到实名，其他的弹窗显示iccid，接入号，提示用户截图保存， 下一步跳转实名需要填写
};

const mutations = {
  SET_APPID: (state, appid) => {
    state.appid = appid;
  },
  SET_PAY_INFO: (state, info) => {
    console.log("set paytype: " + info.payType);
    state.payType = info.payType;
    state.rechargeValues = info.rechargeValues;
    state.needBindPhone = info.needBindPhone === null ? true : info.needBindPhone;
  },
  SET_BALANCE_INFO: (state, info) => {
    state.balance = info.balance;
    state.setPassword = info.setPassword;
  },
  SET_CARD_INFO: (state, info) => {
    state.cardInfo = info;
  },
  SET_REAL_NAME: (state, done) => {
    state.realname = done;
  },
  SET_VALIDATE_URL: (state, url) => {
    state.validateUrl = url;
  },
  SET_ACCESS_NUMBER: (state, accessNumber) => {
    state.accessNumber = accessNumber;
  },
  SET_CARRIER_COMPANY: (state, carrierCompany) => {
    state.carrierCompany = carrierCompany;
  }
};

const actions = {
  // 获取pay type
  getAppContext({ state, commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters.ccid || rootGetters.ccid === '') {
        reject(new Error('ccid 为空，请重新登录'));
      }
      appContext(state.appid)
        .then((res) => {
          const data = res.body.data;
          if (!data) {
            reject(new Error("获取支付类型失败，请稍后再试"))
          }
          commit("SET_PAY_INFO", data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        })
    })
  },
  getBalanceInfo({ commit }) {
    return new Promise((resolve, reject) => {
      myBalance()
        .then((res) => {
          const data = res.body.data;
          if (!data) {
            reject(new Error("获取余额失败，请稍后再试"))
          }
          commit("SET_BALANCE_INFO", data);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        })
    })
  },
  // 获取card info
  getCardInfo({ commit }) {
    return new Promise((resolve, reject) => {
      cardInfo()
        .then((res) => {
          const data = res.body.data;
          if (!data) {
            reject(new Error("获取基本信息失败，请重新登录"));
            return;
          }
          commit("SET_CARD_INFO", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getRealname({ commit }) {
    return new Promise((resolve, reject) => {
      getRealNameStatus()
        .then((res) => {
          const data = res.body.data;
          commit("SET_REAL_NAME", data.realName);
          commit("SET_VALIDATE_URL", data.validateUrl);
          commit("SET_ACCESS_NUMBER", data.accessNumber);
          commit("SET_CARRIER_COMPANY", data.carrierCompany)
          resolve(data.realName);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
