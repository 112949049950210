module.exports = {
  local: {
    baseUrl: "/dev-api",
    mockUrl: "http://127.0.0.1:7001",
  },
  development: {
    baseUrl: "http://wulian.chanben.com/admin/api",
    // baseUrl: "http://iot.liuliang888.top/admin/api",
    // baseUrl: "http://dianxin.wulianka.info/admin/api",
    // baseUrl: "http://sim.video-box.cn/admin/api",
    mockUrl: "https://api.xwhx.top",
  },
  production: {
    baseUrl: "http://wulian.chanben.com/admin/api",
    // baseUrl: "http://iot.liuliang888.top/admin/api",
    // baseUrl: "http://dianxin.wulianka.info/admin/api",
    // baseUrl: "http://sim.video-box.cn/admin/api",
    mockUrl: "https://api.xwhx.top",
  },
};
