import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/wxauth",
    name: "WxAuth",
    component: () => import("@/views/wxauth"),
    meta: {
      title: "微信授权",
    },
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/home"),
    meta: {
      title: "首页",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/purchase",
    name: "Purchase",
    component: () => import("@/views/purchase"),
    meta: {
      title: "订购套餐",
    },
  },
  {
    path: "/purchase/pay",
    name: "PurchasePay",
    component: () => import("@/views/purchase/pay"),
    meta: {
      title: "支付",
    },
  },
  {
    path: "/coupon/buy",
    name: "CouponBuy",
    component: () => import("@/views/coupon/buy"),
    meta: {
      title: "购买优惠券",
    },
  },
  {
    path: "/coupon/pay",
    name: "CouponPay",
    component: () => import("@/views/coupon/pay"),
    meta: {
      title: "支付",
    },
  },
  {
    path: "/coupon/mine",
    name: "CouponMine",
    component: () => import("@/views/coupon/mine"),
    meta: {
      title: "我的优惠券",
    },
  },
  {
    path: "/query",
    name: "Query",
    component: () => import("@/views/query"),
    meta: {
      title: "流量查询",
    },
  },
  {
    path: "/diagnosis",
    name: "Diagnosis",
    component: () => import("@/views/diagnosis"),
    meta: {
      title: "诊断结果",
    },
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: () => import("@/views/feedback"),
    meta: {
      title: "问题反馈",
    },
  },
  {
    path: "/feedback/add",
    name: "FeedbackAdd",
    component: () => import("@/views/feedback/add"),
    meta: {
      title: "添加工单",
    },
  },
  {
    path: "/feedback/detail",
    name: "FeedbackDetail",
    component: () => import("@/views/feedback/detail"),
    meta: {
      title: "问题反馈回复",
    },
  },
  {
    path: "/bindphone",
    name: "BindPhone",
    component: () => import("@/views/bindphone"),
    meta: {
      title: "绑定手机",
    },
  },
  {
    path: "/bindphone/change",
    name: "BindPhoneChange",
    component: () => import("@/views/bindphone/change"),
    meta: {
      title: "绑定手机号",
    },
  },
  {
    path: "/records",
    name: "Records",
    component: () => import("@/views/records"),
    meta: {
      title: "充值记录",
    },
  },
  {
    path: "/balance",
    name: "Balance",
    component: () => import("@/views/balance"),
    meta: {
      title: "我的余额",
    },
  },
  {
    path: "/setpassword",
    name: "SetPassword",
    component: () => import("@/views/balance/password"),
    meta: {
      title: "设置密码",
    },
  },
  {
    path: "/balanceorder",
    name: "BalanceOrder",
    component: () => import("@/views/balance/record"),
    meta: {
      title: "余额明细",
    },
  },
  {
    path: "*",
    name: "404",
    component: () => import("@/views/exception/404"),
    meta: {
      title: "404",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

export default router;
