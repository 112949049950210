import request from "@/utils/request";
import store from "../store";
import md5 from "blueimp-md5";

// wx6ec5df2da8d1f8ac
export function wxRedirect(url) {
  const appid = store.getters.appid;
  return request({
    url: `/wx/${appid}/redirect?url=${encodeURIComponent(url)}`,
    method: "get",
  });
}

export function wxLogin(code) {
  const appid = store.getters.appid;
  return request({
    url: `/wx/${appid}/wxLogin?code=${code}`,
    method: "get",
  });
}

export function wxInitConfig(url) {
  const appid = store.getters.appid;
  return request({
    url: `/wx/${appid}/jsapi?url=${url}`,
    method: "get",
  });
}

export function wxPayCreateOrder(packId, next, couponId) {
  return request({
    url: `/product/order/mp/create`,
    method: "post",
    data: { packId, next, couponId },
  });
}

// 获取套餐列表
export function getPackList() {
  return request({
    url: "/product/productPack/packList/ccid/dx",
    method: "get",
  });
}

// 试用套餐
export function trailProduct() {
  return request({
    url: "/product/card/orderTrailProduct/dx",
    method: "post",
  });
}

// 获取实名状态
export function getRealNameStatus() {
  return request({
    url: "/product/card/realname/dx",
    method: "get",
  });
}

// 获取绑定状态
export function checkBind(key) {
  return request({
    url: `/product/card/checkBind/dx?key=${key}`,
    method: "get",
  });
}

// 卡诊断
export function cardDetect() {
  return request({
    url: `/product/card/detect`,
    method: "get",
    timeout: 60000,
  });
}

// 获取验证码
export function sendSms(phone) {
  return request({
    url: `/product/productBind/sms?phone=${phone}`,
    method: "get",
  });
}

// 绑定手机
export function bindPhone(phone, sms) {
  return request({
    url: `/product/card/phone/bind`,
    method: "post",
    data: {
      phone,
      validateCode: sms,
    },
  });
}

// 重新绑定手机
export function rebindPhone(phone, sms) {
  return request({
    url: `/product/card/phone/rebind`,
    method: "post",
    data: {
      phone,
      validateCode: sms,
    },
  });
}

// 根据appid获取当前渠道的paytype
export function appContext(appid) {
  return request({
    url: `/context/appid?appid=${appid}`,
    method: "get"
  });
}

// 查询帐户余额
export function myBalance() {
  return request({
    url: `/balance/`,
    method: "get"
  })
}

// 设置支付密码
export function setPassword(password) {
  return request({
    url: `/balance/setPassword`,
    method: "post",
    data: { password }
  })
}

// 获取余额明细
export function balanceOrder() {
  return request({
    url: "/balance/rechargeOrders",
    method: "get",
  });
}

// 使用余额支付
export function balancePay(packId, password, next) {
  const ccid = store.getters.ccid;
  const timestamp = Date.now();
  const sign = md5(ccid + "#" + packId + "#" + password + "#" + timestamp);
  return request({
    url: "/balance/pay",
    method: "post",
    data: {
      ccid,
      packId,
      password,
      timestamp,
      sign,
      next
    }
  });
}

// 余额充值下单
export function balanceRecharge(rechargeAmount) {
  const ccid = store.getters.ccid;
  return request({
    url: `/product/order/mp/recharge/create`,
    method: "post",
    data: {
      ccid,
      rechargeAmount
    }
  })
}

// 查询当月使用量
export function cardInfo() {
  return request({
    url: `/product/card/info/dx`,
    method: "get",
  });
}

// 获取订单列表
export function myOrder() {
  return request({
    url: "/pay/payOrder/myOrder",
    method: "get",
  });
}

// 新增反馈
export function feedbackCreate(question) {
  return request({
    url: "/feedback/add",
    method: "post",
    data: { question },
  });
}

// 查询反馈列表
export function feedbackList(type) {
  return request({
    url: `/feedback/list?type=${type}`,
    method: "get",
  });
}

// 设备解绑
export function deviceRebind() {
  return request({
    url: `/product/card/deviceRebind/dx`,
    method: "get",
  });
}

// 重置卡的网络
export function resetNetwork() {
  return request({
    url: `/product/card/resetNetWork/dx`,
    method: "get",
  });
}

// 查询可以购买优惠券组
export function couponGroupList() {
  return request({
    url: `/coupon/groupList`,
    method: "get"
  });
}

// 查询我的优惠券
export function couponMyList() {
  return request({
    url: `/coupon/list`,
    method: "get"
  });
}

// 查看可用优惠券
export function couponAvailable(packId) {
  return request({
    url: `/coupon/availableCoupon?packId=${packId}`,
    method: "get"
  });
}

// 优惠券微信下单
export function couponMpCreate(couponGroupId) {
  return request({
    url: `/coupon/mp/create`,
    method: "post",
    data: {
      couponGroupId
    }
  })
}
